:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  font-family: 'Roboto';
}

body {
  line-height: 1.35 !important;
  font-family: 'Roboto';
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: @font-size-lg;
}

h5 {
  font-size: @font-size-base;
}

h6 {
  font-size: @font-size-base;
}

.ant-form-item-explain-error {
  padding: 4px 16px;
  background-color: #FFF1F0;
  color: #FF4D4F;
  font-weight: 400;
  line-height: 22px;
  margin: 4px 0;
  border-radius: 8px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar {
  width: 5px;
}

.ant-input-number-handler-wrap {
  visibility: hidden;
}

.ant-message {
  z-index: 99999;
}

.ant-form-item-explain {
  margin-bottom: 12px;
}

.ant-input-wrapper {
  background-color: #F2F4F7 !important;
  border-radius: 6px;
  margin-bottom: 24px;

  & input {
    background-color: #F2F4F7 !important;
    border: 0;
    border-radius: 6px;
  }

  & .ant-btn {
    background-color: #F2F4F7 !important;
    border: 0;
    height: 30px;
  }
}

input, .ant-select-selector, .ant-picker, .ant-input-affix-wrapper {
  background-color: #F2F4F7 !important;
}

.ant-input-affix-wrapper {
  border: none;
}

.ant-popover {
  z-index: 1052 !important;
}

.ant-select-selector {
  height: 32px !important;
}


@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 900;
  src: url("./../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Black.otf");
}

@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 700;
  src: url("./../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf");
}

@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 800;
  src: url("./../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Heavy.otf");
}

@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 300;
  src: url("./../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Light.otf");
}

@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 500;
  src: url("./../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Medium.otf");
}

@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 400;
  src: url("./../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf");
}

@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 600;
  src: url("./../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Semibold.otf");
}

@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 200;
  src: url("./../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Thin.otf");
}

@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 100;
  src: url("./../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Ultralight.otf");
}