@import "../../../shared/styles/variable";

.main-content-view {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 16px;
  min-height: auto;
  background-color: #EEF2F6;

  @media screen and (min-width: @screen-md) {
    padding: 16px;
  }
}

.bg-color-view{ 
  padding: 0px;
  width: 100%;
  height: 100vh;
  background-color: #002e66;
  background-image: url("../../../../public/static/images/bg-auth.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  mix-blend-mode: multiply;
}
@primary-color: #0A8FDC;