.pointer {
  cursor: pointer;
}
img {
  max-width: 100%;
}
.item-hover {
  transition: all 0.2s ease;
  transform: scale(1);
}
.item-hover:hover {
  background-color: rgba(10, 143, 220, 0.1);
  transform: translateY(-2px);
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}
.card-hover {
  transition: all 0.3s ease;
  transform: scale(1);
}
.card-hover:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.45);
  transform: scale(1.05);
}
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.text-lowercase {
  text-transform: lowercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-left {
  text-align: left;
}
[dir="rtl"] .text-left {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
[dir="rtl"] .text-right {
  text-align: left;
}
.background-image {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.cr-btn {
  box-sizing: border-box;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
}
.cr-btn-circle {
  background-color: transparent;
  border-color: transparent;
  color: #9e9e9e;
  box-shadow: none;
}
.cr-btn-circle:hover,
.cr-btn-circle:focus {
  color: rgba(0, 0, 0, 0.85);
}
.cr-btn-circle .anticon {
  font-size: 20px;
}
.btn-secondary {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  color: #fff;
}
.btn-secondary:hover,
.btn-secondary:focus {
  background-color: #cd0002;
  border-color: #cd0002;
  color: #fff;
}
.btn-light-blue {
  background-color: rgba(10, 143, 220, 0.1);
  color: #0A8FDC;
  border-color: rgba(10, 143, 220, 0.1);
}
.btn-light-blue:hover,
.btn-light-blue:focus {
  background-color: rgba(10, 143, 220, 0.2);
  color: #0A8FDC;
  border-color: rgba(10, 143, 220, 0.2);
}
.btn-secondary-outline {
  background-color: transparent;
  border-color: #ff4d4f;
  color: #ff4d4f;
  box-shadow: none;
}
.btn-secondary-outline:hover,
.btn-secondary-outline:focus {
  background-color: transparent;
  border-color: #ff0003;
  color: #ff0003;
}
.btn-primary-outline {
  background-color: transparent;
  border-color: #0A8FDC;
  color: #0A8FDC;
  box-shadow: none;
}
.btn-primary-outline:hover,
.btn-primary-outline:focus {
  background-color: transparent;
  border-color: #075f93;
  color: #075f93;
}
.btn-white-outline {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
  box-shadow: none;
}
.btn-white-outline:hover,
.btn-white-outline:focus {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.dot {
  height: 12px;
  width: 12px;
  display: block;
  border-radius: 50%;
}
.cr-dropdown-link {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.84) !important;
  font-size: 20px;
}
.cr-dropdown-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.close-btn {
  background-color: transparent;
  box-shadow: none;
  border: 0 none;
  padding: 0;
  height: auto;
}
.close-btn:hover,
.close-btn:focus {
  border: 0 none;
}
.close-btn .anticon {
  font-size: 20px;
}
.page-title {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  margin-bottom: 16px;
  font-size: 16px;
  display: inline-block;
}
.ant-checkbox-inner {
  width: 18px;
  height: 18px;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  width: 12px;
  height: 12px;
}
.ant-modal {
  max-width: 90%;
}
.text-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.slick-slider-global .slick-dots li {
  width: 12px;
  height: 12px;
  margin: 0 2px;
  vertical-align: middle;
}
.slick-slider-global .slick-dots li button {
  width: 12px;
  height: 12px;
  padding: 0;
}
.slick-slider-global .slick-dots li button::before {
  width: 12px;
  height: 12px;
  font-size: 10px;
  line-height: 12px;
  color: #be8658;
}
.slick-slider-global .slick-dots li.slick-active button::before {
  color: #825a44;
  opacity: 0.75;
}
.z-index-20 {
  z-index: 20;
}
.ant-picker {
  padding-top: 6px;
  padding-bottom: 6px;
}
.ant-pagination li {
  margin-bottom: 4px;
  margin-top: 4px;
}
.ant-pagination.mini .ant-pagination-item,
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  margin-bottom: 4px;
  margin-top: 4px;
}
.ant-drawer,
.ant-modal-wrap,
.ant-modal-mask,
.ant-picker-dropdown,
.ant-select-dropdown,
.ant-message {
  z-index: 1051;
}
.ant-drawer-close {
  color: rgba(0, 0, 0, 0.45);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  top: 11px;
  right: 6px;
  padding: 5px;
}
.ant-drawer-close:hover,
.ant-drawer-close:focus {
  background-color: rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.85);
}
.ant-tooltip-inner a {
  color: #fff;
  display: flex;
  align-items: center;
}
.ant-tooltip-inner a .ant-menu-item-icon {
  margin-right: 6px;
}
.ant-tooltip-inner a .ant-menu-item-icon .anticon,
.ant-tooltip-inner a .ant-menu-item-icon svg {
  display: block;
}
:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  font-family: 'Roboto';
}
body {
  line-height: 1.35 !important;
  font-family: 'Roboto';
}
* {
  margin: 0;
  padding: 0;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
img {
  max-width: 100%;
  height: auto;
}
h1 {
  font-size: 22px;
}
h2 {
  font-size: 20px;
}
h3 {
  font-size: 18px;
}
h4 {
  font-size: 16px;
}
h5 {
  font-size: 14px;
}
h6 {
  font-size: 14px;
}
.ant-form-item-explain-error {
  padding: 4px 16px;
  background-color: #FFF1F0;
  color: #FF4D4F;
  font-weight: 400;
  line-height: 22px;
  margin: 4px 0;
  border-radius: 8px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}
::-webkit-scrollbar {
  width: 5px;
}
.ant-input-number-handler-wrap {
  visibility: hidden;
}
.ant-message {
  z-index: 99999;
}
.ant-form-item-explain {
  margin-bottom: 12px;
}
.ant-input-wrapper {
  background-color: #F2F4F7 !important;
  border-radius: 6px;
  margin-bottom: 24px;
}
.ant-input-wrapper input {
  background-color: #F2F4F7 !important;
  border: 0;
  border-radius: 6px;
}
.ant-input-wrapper .ant-btn {
  background-color: #F2F4F7 !important;
  border: 0;
  height: 30px;
}
input,
.ant-select-selector,
.ant-picker,
.ant-input-affix-wrapper {
  background-color: #F2F4F7 !important;
}
.ant-input-affix-wrapper {
  border: none;
}
.ant-popover {
  z-index: 1052 !important;
}
.ant-select-selector {
  height: 32px !important;
}
@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 900;
  src: url("../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Black.otf");
}
@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 700;
  src: url("../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Bold.otf");
}
@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 800;
  src: url("../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Heavy.otf");
}
@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 300;
  src: url("../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Light.otf");
}
@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 500;
  src: url("../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Medium.otf");
}
@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 400;
  src: url("../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Regular.otf");
}
@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 600;
  src: url("../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Semibold.otf");
}
@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 200;
  src: url("../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Thin.otf");
}
@font-face {
  font-family: "SF-Pro-Display";
  font-weight: 100;
  src: url("../../../public/static/fonts/SF-Pro-Display/SF-Pro-Display-Ultralight.otf");
}
